.deliveryPic {
	width: 100%;
	margin-bottom: 2rem;
	max-height: 20rem;
	object-fit: cover;
	border-radius: 5px;
}

.deliverySpec {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 1rem;
}

.deliverySpec1 {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 0rem;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #eeeeee;
}

.icon {
	min-width: 1.5rem;
	min-height: 1.5rem;
}

.paypalLogo {
	height: 24px;
	margin-left: 0.5ch;
}

.paypalLogo1 {
	height: 24px;
	width: 24px;
	border-radius: 100%;
}

.main {
	position: relative;
	padding: 2rem;
}

.closeIcon {
	position: absolute;
	top: 1rem;
	right: 1rem;
	color: rgb(38, 38, 38);
	width: 1.5rem;
	height: 1.5em;
}

.title {
	font-weight: 500;
	margin-bottom: 2rem;
	font-size: 20px;
}

.title1 {
	font-weight: 500;
	margin-bottom: 0.5rem;
	font-size: 20px;
}
.title2 {
	font-weight: 500;
	margin-bottom: 0rem;
	font-size: 20px;
}

.closeIcon:hover {
	cursor: pointer;
}

.infoText {
	line-height: 1.5;
	padding-left: 1rem;
	font-size: 14px;
	color: var(--text-main);
}

.infoText1 {
	line-height: 1.5;

	font-size: 14px;
	color: black;
}

.footer {
	padding-top: 1rem;
	margin-top: 0.5rem;
	border-top: 1px solid #eeeeee;
	font-size: 14px;
}
