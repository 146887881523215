.wrapper {
  position: relative;
  width: 100%;
}

.searchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  /* background-color: transparent; */
  transition: all 0.1s ease-in-out;
  border-radius: 3px !important;
  border: 1px solid var(--primary-border);
  position: relative;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.listingImg {
  max-width: 30px;
  max-height: 30px;
  min-height: 30px;
  min-width: 30px;
  object-fit: cover;

  border-radius: 3px;
}

.searchBar::placeholder {
  color: var(--text-body);
}

.searchBar:hover {
  cursor: pointer;
  border: 1px solid var(--text-body);
}

.searchBar:focus {
  box-shadow: 0 0 5px var(--primary-purple);
}

.input {
  width: 100%;
  padding: 0;
  height: 100%;
  border: none;
}

.input:focus {
  outline: none !important;
  border: none !important;
}

.input:active {
  outline: none !important;
  border: none !important;
}

.dropDownAbsolute {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  border: 1px solid var(--primary-border);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  overflow: hidden;
}

.option2 {
  border-bottom: 1px solid var(--primary-border);
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  color: var(--text-body);
  transition: all 0.3s ease;
  overflow: hidden;
  text-align: center;
}

.gap10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--primary-border);
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-body);
  transition: all 0.3s ease;
  overflow: hidden;
}

.option:hover {
  background-color: var(--primary-bg-light);
}

.categories {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
}

.category {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  border-bottom: 1px solid var(--primary-border);
  font-size: 12px;
}

.lastCategory {
  border-bottom: none;
}

.close {
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--text-body);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  transition: all 0.3s ease;
}

.close:hover {
  background-color: var(--primary-bg);
  transform: scale(1.02);
}
