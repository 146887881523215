.Button {
  background-color: var(--text-main);
  border: 1px solid var(--text-main);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  min-width: 5em;
  outline: none;
  font-family: "Editorial";
  outline: 0;
  padding: 10px 15px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.Button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.text {
  margin-bottom: 0rem;
  font-family: "Editorial";
}
