.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
}

.info {
	position: relative;
	text-align: center;

	font-weight: 500;
	color: var(--text-main);
	font-size: 22px;
	padding-top: 2rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 2rem;
}

.infoText {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	color: var(--text-body);
}
.infoText1 {
	margin-top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	margin-bottom: 0rem;
	color: var(--text-body);
}

.titleText {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 0rem;
	line-height: 150%;
	margin-left: auto;
	margin-right: auto;
	max-width: 70%;
}

.closeIcon {
	position: absolute;
	top: 20px;
	right: 20px;
	color: var(--text-body);
	height: 35px;
	width: 35px;
}
.closeIcon:hover {
	cursor: pointer;
	color: black;
}
.modal {
	position: fixed;
	top: 40px;
	left: 0;
	right: 0;
	margin: auto;
	width: 40%;
	border-radius: 5px;
	background-color: var(--primary-bg);
	z-index: 5000;
	margin-top: 3em;
	overflow-y: scroll;
}

.maxWidth {
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding-bottom: 2rem;
}

.smallText {
	font-size: 12px;
	display: flex;
	color: var(--text-body);
}

.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.error {
	color: var(--primary-red-400);
	width: 100%;
	text-align: center;
}

.goBack {
	margin-top: 0.5em;
	color: rgba(0, 0, 0, 0.404);
	font-style: italic;
}

.goBack:hover {
	cursor: pointer;
}

.logout:hover {
	cursor: pointer;
}

.logout {
	width: 100%;
	text-align: center;
}

@media (max-width: 600px) {
	.modal {
		width: 95%;
		height: 90%;
		top: 0;
		overflow-y: scroll;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.container {
		width: 95%;
	}
}

.leftContent {
	width: 37%;
	display: flex;
	flex-direction: column;
	background-color: var(--primary-bg);
}
.rightContent {
	width: 63%;
	overflow: scroll;
	position: relative;
	padding-bottom: 2em;
}

.armChair {
	margin-top: 3em;
	margin-left: -3em;
	width: 100%;
}

.tabs {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 0.5rem;

	margin: auto;
	margin-top: 0em;
	text-align: center;
	justify-content: center;
}

.tab {
	width: 33%;
	border-radius: 3px;
	/* border: 1px solid var(--primary-border); */
	height: 46px;
	text-align: center;
	cursor: pointer;
	font-weight: 400;
	background-color: white;
	border: 1px solid var(--primary-border);
	display: flex;
	color: var(--text-main);
	justify-content: center;
	font-family: 'Editorial';
	align-items: center;
}

.tab:hover {
	border: 1px solid var(--text-main);
	color: var(--text-main);
}

.tabSelected {
	width: 33%;
	cursor: pointer;
	border: 1px solid var(--text-main);
	color: var(--text-main);

	background-color: rgb(246, 246, 246);
	height: 46px;
	font-family: 'Editorial';

	border-radius: 3px;

	font-weight: 400;
	background-color: white;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tabSelected:hover {
	color: var(--text-main);
}

.formDiv {
	width: 360px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1em;
}

.buttons {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.button {
	width: 100% !important;
	background-color: var(--text-main);
	color: white;
	font-family: 'Editorial';
	font-size: 16px;
	height: 46px;
	display: flex;
	align-items: center;
	border-radius: 3px;
	justify-content: center;
	cursor: pointer;
}

.button:hover {
	opacity: 0.9;
}

.googleButton {
	width: 100%;
	box-shadow: none !important;
	border-radius: 3px !important;
	text-align: center !important;
	padding: 0.5em !important;
	font-size: 0.8em !important;
	height: 2.7em;
	border: 1px solid var(--primary-border) !important;
	display: inline !important;
}

.googleButton div {
	margin-left: 10px;
	padding: 0 !important;
	display: inline;
}

.googleButton span {
	/* margin-left: 10px; */

	padding: 0 !important;
	display: inline;
}

.absolute {
	display: inline-block;
	position: fixed;
	font-size: 14px;
	right: 1%;
	top: 40px;
	width: 15%;
}

.Link {
	color: var(--text-body);
	text-align: center;
	text-decoration: none;
}

.Link:hover {
	color: var(--text-main);

	text-decoration: none;
}

.header {
	width: 360px;
	margin-top: 2em;
	margin-right: auto;
	margin-left: auto;
}

.headerText {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 1.5em;
}

.leftSection {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 3em;
	padding-top: 2em;
	padding-right: 3em;
}

.anchor {
	display: inline-block;
	text-decoration: underline;
	margin-bottom: 0rem;
	cursor: pointer;
	color: var(--text-body);
	margin-left: 0.5ch;
}

.logInWithEmail {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.helpLinks {
	background-color: transparent !important;
	font-weight: 300;
	font-size: 12px;
	text-align: center;
	display: flex;
	justify-content: center;
	width: 100%;
	border: 0 !important;
	box-shadow: none !important;
	color: var(--text-body);
}

@media (max-width: 1000px) {
	.absolute {
		position: inherit;
		display: flex;
		top: 1em;
		right: 5%;

		width: fit-content;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2em;
	}
}

@media (max-width: 900px) {
	.leftContent {
		display: none;
	}

	.rightContent {
		width: 100%;
	}

	.header {
		width: 85%;
		max-width: 30em;
	}

	.formDiv {
		width: 85%;
		max-width: 30em;
	}
}

.headerParagraph {
	color: #99a3a6;
	margin-bottom: 1.5em;
}

.logo {
	width: 50%;
	padding-top: 4em;
	margin-bottom: 3em;
}

.text {
	font-size: 32px;
	font-weight: 600;
	width: 95%;
	margin-bottom: 1em;
	color: var(--primary-purple);
}

.logo:hover {
	cursor: pointer;
}

.inputContainer {
	position: relative;
	width: 100%;
}

.lense {
	position: absolute;
	right: 2%;
	top: 25%;
	color: gray;
	height: 16px;
	cursor: pointer;
}

.lense:hover {
	color: black;
	transform: scale(1.1);
}

.main {
	height: 100vh; /* You must set a specified height */
	background-repeat: no-repeat; /* Do not repeat the image */
	/* overflow: auto; */
	display: flex;
}
.newListingDiv {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 20px;
	margin: 2em;
	padding: 1em;
	position: relative;
}

.visibleAtWidth {
	display: none;
}

@media (max-width: 900px) {
	.visibleAtWidth {
		display: flex;
	}

	.logo {
		width: 40%;
		padding-top: 0em !important;
		margin-bottom: 1em !important;
	}

	.tab {
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.tabSelected {
		height: auto;
		background-color: var(--text-main);
		color: white;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.tabSelected:hover {
		color: white;
		opacity: 0.8;
	}
}

.newListingHeader {
	width: 100%;
	text-align: center;
	font-weight: 600;
	font-size: 1.5em;
	margin-bottom: 1em;
}

.listingPicture {
	max-width: 6em;
	border-radius: 10px;
	display: inline;
}

.listingTitle {
	display: inline;
	margin-left: 1em;
	font-weight: 600;
	color: var(--primary-purple);
}

.listingPrice {
	position: absolute;
	top: 3em;
	right: 2em;
	color: var(--primary-purple);
	font-weight: 800;
	font-size: 1.5em;
}

.separator {
	display: flex;
	align-items: center;
}

.separatorText {
	color: var(--text-mute);
	font-weight: 400;
	padding: 0 0.5rem;
	margin-bottom: 0rem !important;
}

.line {
	flex: 1;
	height: 0.5px;
	background-color: var(--text-mute);
}

.formControl {
	border: none !important;
}

.separator1 {
	margin-top: 2rem;
	display: flex;
	align-items: center;
}

.line1 {
	flex: 1;
	height: 5px;
	background-color: white;
}
