.buttonDiv {
	display: grid;
	grid-template-columns: 1fr 1fr;
	/* grid-template-rows: auto auto; */
	grid-gap: 10px; /* Optional spacing between boxes */
}

.secondaryButton {
	background-color: white;
	grid-column: span 1; /* Span two columns */
	grid-row: 1/ 2; /* Occupy the first row */
	border: 1px solid var(--text-main);
	font-family: 'Editorial';

	box-sizing: border-box;
	color: var(--text-main);
	padding: 0.875rem;
}

.secondaryButton:hover {
	opacity: 0.6;
}

.primaryButton {
	grid-column: span 1; /* Span two columns */
	grid-row: 1 / 2; /* Occupy the first row */
	background-color: var(--text-main);
	color: white;
	padding: 0.875rem;
	font-family: 'Editorial';
}

.primaryButton:hover {
	opacity: 0.8;
}

.secondaryButtonSpan2:hover {
	opacity: 0.6;
}

.secondaryButtonSpan2 {
	grid-column: span 2; /* Span two columns */
	grid-row: 2 / 2; /* Occupy the first row */
	background-color: white;
	border: 1px solid var(--text-main);
	box-sizing: border-box;
	color: var(--text-main);
	font-family: 'Editorial';

	padding: 0.875rem;
}

@media (max-width: 600px) {
	.buttonDivFixed {
		display: grid;
		position: relative;
		column-gap: 10px;
		row-gap: 10px;
		padding: 10px;
		z-index: 3;
		width: 100%;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 2;
		gap: 10px; /* Adjust the gap value as per your needs */
		position: relative;
		display: grid;
		column-gap: 10px;
		row-gap: 10px;
		background-color: white;
		position: fixed;
		bottom: 0;
		left: 0;
	}
}
