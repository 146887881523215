.main {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 2rem;
}

.closeIcon {
  position: absolute;
  top: 1rem;
  cursor: pointer;
  right: 1rem;
  color: rgb(38, 38, 38);
  width: 2rem;
  height: 2rem;
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.title {
  font-weight: 500;
  margin-bottom: 0rem;
  font-size: 20px;
}

.button3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-purple);
  box-shadow: 2px 10px 25px rgb(99 183 99 / 20%);
  border-radius: 5px;
  border: 1px solid var(--primary-purple);
  color: #fff;
  height: 60px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Editorial";
  text-transform: capitalize;
  width: 171px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0rem;
}

.infoBox {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  margin-bottom: 0rem;
}

.label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0rem;
}

.input {
  padding-left: 1rem;
  width: 100%;
  height: 70px;
  font-size: 14px;

  background: var(--primary-bg);
  border-radius: 5px;
  border: 0px;
}

.mute {
  color: var(--text-mute);
  font-size: 14px;
  font-family: "Editorial";
  margin-bottom: 1rem;
  margin-top: 0rem;
  font-weight: 400;
  margin-bottom: 0rem;
  line-height: 150%;
  margin-left: auto;
  margin-right: auto;
}
