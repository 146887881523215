.closeIcon {
	z-index: 20;
	position: absolute;
	top: 10px;
	right: 10px;
	width: 23px;
	height: 23px;
}

.closeIcon:hover {
	cursor: pointer;
}

.addCard {
	align-items: center;
	display: flex;
	flex-direction: row;
	column-gap: 16px;
}

.addCardText {
	font-size: 16px;
	font-weight: 600;
	color: var(--text-body);
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 8px;
}

.addCardTextActive {
	font-size: 16px;
	font-weight: 600;
	color: var(--primary-purple);
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 8px;
}

.listingCard {
	padding: 32px;
	border-radius: 10px;
	position: relative;
	width: 100%;
	max-width: 40em;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}
.listingCard1 {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding: 1rem;
	border: 1px solid var(--primary-border);
	border-radius: 4px;
	position: relative;
	width: 100%;
	height: fit-content;
	transition: all 0.2s ease-in-out;
}

.listingCard1:hover {
	cursor: pointer;
	box-shadow: var(--box-shadow-2);
}

.submitButton {
	background-color: var(--primary-purple);
	border-radius: 3px;
	border: 0 !important;
	width: 100%;
	align-self: center;
}

.noMargin {
	font-size: 14px;
	font-weight: 300;
}

.titleText {
	font-size: 22px;
	margin-bottom: 1rem;
	font-weight: 600;
}

.bodyText {
	font-size: 14px;
	font-weight: 600;
}

.infoText {
	margin-bottom: 1.2rem;
	font-size: 14px;
	height: fit-content;
	color: var(--text-mute);
}

.infoText1 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 14px;
	height: fit-content;
	color: var(--text-mute);
}

.stripeEl {
	border-radius: 6px;
	padding: 12px;
	border: 1px solid rgba(50, 50, 93, 0.1);
	height: 44px;
	font-size: 16px;
	width: 100%;
	background: white;
}

.cardLogo {
	width: 10%;
	height: 1.5em;
	border: 0.2px solid #99a3a6;
	padding: 3px;
	border-radius: 2px;
	margin-right: 0.2rem;
}

.error {
	color: var(--primary-red-400);
	text-align: center;
	margin-top: 0.5em;
}

.icons {
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
}

.round {
	border-radius: 50%;
	width: 1rem;
	height: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--text-body);
}

.roundActive {
	border-radius: 50%;
	width: 1rem;
	height: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--primary-purple);
}

.roundActive .roundInner {
	border-radius: 50%;
	width: 65%;
	height: 65%;
	background-color: var(--primary-purple);
}

@media (max-width: 600px) {
	.cardLogo {
		width: 15%;
	}
}
