.title {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
  color: var(--text-main);
  font-family: "Editorial";
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  row-gap: 1.5rem;
  background-color: var(--primary-bg);
  border-bottom: 1px solid var(--primary-border);
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
.modalBody {
  display: flex;
  flex-direction: column;
}
.modalClose {
  color: rgb(38, 38, 38);
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  transition: all 0.3s ease;
  cursor: pointer;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.item:hover {
  background-color: var(--primary-bg-light);
}

.borderBottom {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border-bottom: 1px solid var(--primary-border);
}

.description {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.subTitle {
  font-family: "Editorial";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.descriptionText {
  font-size: 12px;
  font-weight: 300;
  color: var(--text-body);
  margin-bottom: 0;
}

.row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.badge {
  background-color: var(--primary-bg);
  border-radius: 0.5rem;
  font-family: "Editorial";
  padding: 2px 5px;
  font-size: 12px;
  border: 1px solid var(--primary-border);
}
