.main {
	display: flex;
	flex-direction: column;
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
	gap: 1rem;
}

.purchasesContainer {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
}

.headerRow {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 22px;
	font-weight: 500;
	min-width: 20%;
	margin-bottom: 0rem;
}

.navigationColumn {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-width: 20%;
}

.ordersContainer {
	width: 80%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
}

.navigationHeader {
	font-weight: 400;
	color: var(--text-body);
	font-size: 14px;
	padding-bottom: 1rem;
	margin-bottom: 0rem;
}

.navigationSubHeader {
	font-weight: 500;
	cursor: pointer;
	padding: 1rem;
	font-size: 14px;
	color: var(--text-main);
	position: relative;
}

.navigationSubHeader:hover {
	background-color: var(--primary-bg-light);
	border-radius: 4px;
}

.backArrow {
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	padding: 8px;
	color: var(--text-main);
	background-color: white;
	border: 1px solid var(--text-main);
}

.backArrow:hover {
	background-color: var(--primary-bg-light);
}
.navigationSubHeaderSelected {
	font-weight: 500;
	cursor: pointer;
	padding: 1rem;
	font-size: 14px;
	background-color: var(--primary-bg);
	border-radius: 4px;
	color: var(--text-main);
	position: relative;
}

.notificationBubble {
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
	background-color: var(--primary-purple);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 100%;
}

/* Navigation */
.navigation {
	padding-top: 2rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.muteLink {
	font-size: 14px;
	color: #6b6b6b;
	text-decoration: none;
	margin-bottom: 0rem;
	cursor: pointer;
	font-weight: 400;
}

.muteLink:hover {
	color: #222222;
}

.muteLinkActive {
	margin-bottom: 0rem;
	font-size: 14px;
	color: #222222;
	font-weight: 500;
	text-decoration: none;
}

.muteLinkActive:hover {
	color: #222222;
}

.muteLink:hover {
	color: #222222;
}

.arrowRight {
	width: 15px;
	height: 15px;
	color: #6b6b6b;
}
/* Nav done */

/* NoOrders */

.buySomeShit {
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.emptyBox {
	width: 120px;
	height: 120px;
}

.buyText {
	font-size: 16px;
	font-weight: 500;
}

.buyLink {
	color: var(--primary-purple);
	font-size: 16px;
}

.buyLink:hover {
	color: var(--primary-green-500);
}

/* Placeholder */

.placeholderDiv {
	width: 100%;
	height: fit-content;
	border-radius: 8px;

	border: 1px solid var(--primary-border);
}
.placeHolderDescription {
	display: flex;
	flex-direction: row;
	padding: 15px 15px;
}

.placeHolderImg {
	max-height: 100px;
	max-width: 100px;
	min-height: 100px;
	min-width: 100px;
	object-fit: cover;
	border-radius: 3px;
	border: 1px solid var(--primary-border);
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
}

.placeHolderCol {
	margin-top: auto;
	margin-bottom: auto;
	padding-left: 15px;
	display: flex;
	flex-direction: column;
}

.placeHolderBox1 {
	width: 10rem;
	display: flex;
	flex-direction: column;
	height: 1.5rem;
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
	border-radius: 8px;
}
.placeHolderBox2 {
	margin-top: 0.5ch;
	width: 5rem;
	display: flex;
	flex-direction: column;
	height: 1.5rem;
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
	border-radius: 8px;
}

.placeholderBox {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 10rem;
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

@keyframes anim {
	0% {
		background-position: 0% 100%;
	}
	50% {
		background-position: 100% 0%;
	}
	100% {
		background-position: 0% 100%;
	}
}

.modalHeader {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	margin-bottom: 0rem;
	border-bottom: 1px solid var(--primary-border);
}

.modalHeaderTitle {
	font-size: 16px;
	font-weight: 500;

	margin-bottom: 0rem;
}

.modalStepExplain {
	font-size: 14px;
	line-height: 150%;
	font-weight: 500;
	margin-bottom: 4px;
}

.modalTextSub {
	font-size: 12px;
	font-weight: 400;
	color: var(--text-mute);
	margin-bottom: 0rem;
}

.modalFooter {
	padding: 1rem;
	border-top: 1px solid var(--primary-border);
}

.buyNowButton {
	border: 1px solid var(--primary-purple);
	padding: 5px 15px;

	font-size: 14px;

	background-color: var(--primary-purple);
	color: white;
	border-radius: 3px;
}

.buyNowButton:hover {
	background-color: var(--primary-green-500);
}

.deleteOfferButton {
	margin-left: 0.5ch;
	padding: 5px 15px;
	border: 1px solid var(--primary-purple);

	font-size: 14px;

	background-color: transparent;
	color: var(--primary-purple);
	border-radius: 3px;
}

.deleteOfferButton:hover {
	border: 1px solid var(--primary-green-500);

	color: var(--primary-green-500);
}

.closeIcon {
	color: var(--text-body);
	width: 25px;
	cursor: pointer;
	height: 25px;
}

.modalBody {
	padding: 1rem;
}

.navigationMobile {
	display: none;
}

.center {
	display: flex;
	align-items: center;
}

.topRowSearchDiv {
	display: flex;
	flex-direction: column;
	width: 80%;
}

.inputSearch {
	width: 100%;
	font-size: 16px;
	border-radius: 8px;
	border: 0.5px solid rgba(128, 128, 128, 0.42);
	padding: 0.8em;
	height: 100%;
	padding-left: 1em;
}

.inputSearch:focus {
	outline: none !important;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.filterPill {
	padding: 0.5em;
	padding-left: 1em;
	padding-right: 1em;
	height: fit-content;
	margin-right: 1em;
	font-size: 14px;
	background-color: var(--primary-bg);
	border: 1px solid var(--primary-border);
	color: var(--text-main);
	border-radius: 20px;
	text-align: center;
	width: fit-content;
	display: inline-block;
	cursor: pointer;
}

.deleteFilter {
	height: 1em;
	width: 1em;
	margin-right: 0.4em;
	vertical-align: sub;
}

@media (max-width: 600px) {
	.navigation {
		display: none;
	}

	.navigationColumn {
		display: none;
	}
	.muteLink {
		font-size: 10px;
	}

	.muteLinkActive {
		font-size: 10px;
	}

	.mainOrderDiv {
		flex-direction: column;
	}

	.headerRow {
		margin-top: 0rem;
		font-size: 16px;
		gap: 0.75rem;
	}

	.header {
		font-size: 16px;
	}

	.backArrow {
		width: 25px;
		height: 25px;
		padding: 3px;
	}

	.main {
		padding-top: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
		min-height: 100vh;
	}

	.navigationMobile {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.buttonsTop {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.navigationSubButtons {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.buttonTopNav {
		height: 50px;
		border-bottom: 1px solid var(--primary-border);
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 50%;
	}
	.buttonTopNavActive {
		font-weight: 500;

		height: 50px;
		border-bottom: 2px solid var(--primary-dark-green-500);
		display: flex;
		color: var(--primary-dark-green-500);
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 50%;
	}

	.buySomeShit {
		margin-top: 3rem;
	}

	.subButton {
		width: 50%;
		height: 50px;
		display: flex;
		font-size: 12px;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-bottom: 1px solid var(--primary-border);
		position: relative;
	}

	.subButtonActive {
		width: 50%;
		height: 50px;
		display: flex;
		font-size: 12px;
		font-weight: 500;
		color: var(--primary-dark-green-500);
		align-items: center;
		justify-content: center;
		text-align: center;
		border-bottom: 2px solid var(--primary-dark-green-500);
		position: relative;
	}

	.topRowSummary {
		flex-direction: column;
	}

	.topRowSearchDiv {
		width: 100%;
	}

	.ordersContainer {
		width: 100%;
		padding-top: 0rem;
	}
}
