.mainContainer {
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  width: 100%;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.carouselContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.header {
  color: var(--text-main);
  font-family: "Editorial";
  margin-right: auto;
  font-size: 20px;
  margin-bottom: 0rem;
  font-weight: 500;
}
.main {
  position: relative;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  gap: 0.5rem;
  list-style: none;
  padding: 0rem;
  margin: 0rem;
}

.next {
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(76, 76, 76, 0.28);

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.previous {
  cursor: pointer;

  box-shadow: 0px 2px 4px rgba(76, 76, 76, 0.28);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow {
  width: 40%;
}

.carouselMask {
  cursor: grab;
  position: relative;
  display: flex;
  justify-content: flex-start;
}

.carouselMask:active {
  cursor: grabbing;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-right: 16px;
  flex: 0 0 calc(20% - 16px);
}

.imgContainer {
  position: relative;
  display: flex;
  background-color: var(--primary-bg);
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure the container takes the full width */
  height: auto; /* Maintain the aspect ratio */
  max-height: 100%; /* Prevent overflowing vertically */
  overflow: hidden;
  border-radius: 2px;
  transition: 0.2s ease-in-out;
  aspect-ratio: 19/9;
  content: "imgContainer";
}

.imgContainer:hover {
  cursor: pointer;
  box-shadow: var(--box-shadow-1);
}

.imgContainer:hover .img {
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

.img {
  max-width: 100%;
  height: 150% !important;
  width: 100% !important;
  inset: unset !important; /* This will override and remove the default inset property */
  padding: 1rem 1.5rem;
  max-height: 150%;
  /* object-fit: contain; */
}

.headerBox {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.seeAll {
  font-size: 18px;
  font-weight: 400;
  font-family: "Editorial";
  color: var(--text-body);
  margin-bottom: 0rem;
}

.Link {
  text-decoration: none;
}

.seeAll:hover {
  color: var(--text-main);
  cursor: pointer;
}

.placeHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  min-width: 150px;
  max-height: 100px;
  min-height: 100px;
  object-fit: contain;
  transition: 0.2s ease-in;
  border-radius: 10000px;
  margin-left: 1rem;
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(210, 27%, 96%) 0%,
    hsla(180, 3%, 78%, 0.433) 50%,
    hsl(210, 28%, 93%) 100%
  );
  animation: anim 2s infinite;
}

@keyframes anim {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@media (max-width: 600px) {
  .header {
    margin-bottom: 0;
    margin-right: auto;
    font-size: 18px;
  }

  .seeAll {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .container {
    margin-right: 8px;
    flex: 0 0 calc(50% - 8px);
    gap: 8px;
  }

  .main {
    grid-template-columns: repeat(auto-fill, minmax(40%, 2fr));
  }

  .imgContainer {
    max-width: 180%;
  }

  /* .main {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  

  .imgContainer {
    width: 35%;
    min-width: 35%;
    aspect-ratio: 3/2;
    overflow: auto;
  }

} */

  .nextButton {
    right: 0;
  }
  .prevButton {
    left: 0;
  }
}
