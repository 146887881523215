.overflow {
	position: absolute;
	max-height: 20em;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	overflow-y: scroll;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	top: 40px;
	display: flex;
	flex-direction: column;
	gap: 0rem;
	padding: 0;
	margin: 0;
	background-color: white;
	z-index: 2;
	width: 100%;
}

.overflowError {
	position: absolute;
	max-height: 20em;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	overflow-y: scroll;
	border: 1px dashed var(--primary-red-400);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	top: 40px;

	padding: 0px;
	background-color: white;
	z-index: 2;
	width: 100%;
}

.input {
	border: 0;
	width: 100%;
	height: 100%;
	outline: none;
}

.free {
	color: var(--text-main);
	padding: 5px 10px;
	border-radius: 3px;
	font-weight: 400;
	background-color: #d8e3eddf;
}

.strong {
	color: var(--text-main);
	font-weight: 500;
}
.strong2 {
	color: var(--text-main);
	font-weight: 500;
	text-decoration: underline;
}

.strong2:hover {
	cursor: pointer;
	opacity: 0.7;
}

.strong1 {
	color: var(--text-main);
	font-weight: 500;
	font-size: 14px;
}

.main {
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	position: relative;
}

.deleteIcon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	width: 1.5rem;
	height: 1.5rem;
	color: var(--text-main);
}
.loadingSpinner {
	width: 1rem;
	display: inline;
	height: 1rem;
	color: var(--primary-purple);
}

.loadingPlaceholder {
	width: 6rem;
	height: 12px;
	border-radius: 9999px;
	background-size: 200% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsla(0, 0%, 100%, 0.391) 0%,
		/* Lightest shade, almost white but still has contrast */
			hsla(0, 0%, 89%, 0.57) 50%,
		/* Medium shade for visible contrast */ hsl(0, 0%, 95%) 100%
			/* Light shade that's between the lightest and medium */
	);
	animation: anim 2s linear infinite;
	display: inline-block;
	margin-left: 0.5ch;
}

.spinner {
	animation: rotate 1.5s linear infinite;
}

.deleteIcon:hover {
	cursor: pointer;
}

.locationIcon {
	width: 15px;
	height: 15px;
	min-width: 15px;
	min-height: 15px;
	margin-bottom: 2px;
}
.inputVal {
	height: 47px;
	position: relative;
	cursor: pointer;
	-webkit-margin-start: auto;
	border: 1px solid var(--primary-border);
	border-radius: 2px;
	-webkit-margin-end: auto;
	margin-inline: auto;
	font-size: 14px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	margin-bottom: 0rem;
	padding: 12px 20px;
}
.inputVal::placeholder {
	color: var(--text-mute);
	font-size: 14px;
	opacity: 0.7;
}

.inputVal:focus {
	border: 1px solid var(--primary-purple);

	outline: none;
}

.inputValError {
	height: 47px;
	position: relative;
	cursor: pointer;
	-webkit-margin-start: auto;
	border: 1px dashed var(--primary-red-400);
	border-radius: 2px;
	-webkit-margin-end: auto;
	margin-inline: auto;
	font-size: 14px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 0rem;
	padding: 12px 20px;
}

.inputValError::placeholder {
	color: var(--text-mute);
	font-size: 14px;
}

.inputValError:focus {
	border: 1px solid var(--primary-red-400);
	outline: none;
}

.resultsListTop {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	text-decoration: underline;
	background-color: var(--primary-bg-light);
	font-size: 14px;
	padding: 10px 15px;
}

.resultsListTop:hover {
	font-weight: 600;
	background-color: rgba(243, 240, 240, 0.692);
	cursor: pointer;
}

.resultsList {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;

	font-size: 14px;
	padding: 10px 15px;
}

.resultsList:hover {
	font-weight: 600;
	background-color: rgba(243, 240, 240, 0.692);
	cursor: pointer;
	text-decoration: underline;
}

.text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 90%; /* Ensures the ellipsis works */
	margin-bottom: 0rem;
	font-size: 14px;
	font-weight: 400 !important;
}

.gap {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
}
.gapCol {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.justifyBetween {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.text1 {
	font-size: 14px;
	line-height: 100%;
	font-weight: 400 !important;
	color: var(--text-body);

	margin-bottom: 0px;
}

.text2 {
	display: flex;
	align-items: center;
	font-size: 12px;
	font-weight: 400;
	color: var(--text-mute) !important;
	margin-bottom: 0;
}

.text3 {
	font-size: 12px;
	color: var(--text-mute) !important;
	font-weight: 400;
	margin-bottom: 0;
}

.text5 {
	padding: 1rem;
	font-size: 12px;
	color: var(--text-mute) !important;
	margin-bottom: 0;
}

.text3:hover {
	cursor: pointer;
	text-decoration: underline;
}

.col {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.col1 {
	display: flex;
	flex-direction: column;
	align-items: self-end;
	gap: 0.5rem;
}

.changeDestinationButton {
	font-size: 15px;
	color: var(--text-body) !important;
	margin-bottom: 0;
	padding: 0;
	font-weight: 300;
	cursor: pointer;
}

.changeDestinationButton:hover {
	text-decoration: underline;
}

.deliveryIcon {
	width: 25px;
	height: 25px;
	min-width: 25px;
	margin-bottom: 2px;
}
.deliveryIcon1 {
	min-width: 25px;
	width: 25px;
	height: 20px;
}

.resultsListPick {
	position: relative;
	-webkit-margin-start: auto;
	border: 0;
	background-color: white;
	border-bottom: 1px solid #ced4da;
	-webkit-margin-end: auto;
	margin-inline: auto;
	margin-top: 1%;
	font-size: 14px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 0rem;
}

.resultsListPick2 {
	position: relative;
	-webkit-margin-start: auto;
	border: 0;
	background-color: white;
	border-bottom: 1px solid #ced4da;
	-webkit-margin-end: auto;
	margin-inline: auto;
	margin-top: 1%;
	font-size: 14px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 0rem;
}

.resultsListPick:hover {
	font-weight: 600;
	background-color: rgba(255, 255, 255, 0.692);
	cursor: pointer;
}

.close {
	position: absolute;
	right: 5px;
	top: 5px;
	width: 1.5rem;
	height: 1.5rem;
	color: var(--text-main);
}

.close:hover {
	cursor: pointer;
}

.absolute {
	position: relative;
	background-color: white;
	border: 1px solid var(--primary-border-light);
	height: fit-content;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	/* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
	padding: 1rem;
}

.relative {
	position: relative;
}

.locationIcon {
	width: 15px;
	height: 15px;
	min-width: 15px;
	min-height: 15px;
	margin-bottom: 2px;
}

.resultsListTop {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	text-decoration: underline;
	background-color: var(--primary-bg-light);
	font-size: 14px;
	padding: 10px 15px;
}

.resultsListTop:hover {
	font-weight: 600;
	background-color: rgba(243, 240, 240, 0.692);
	cursor: pointer;
}

.resultsList {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;

	font-size: 14px;
	padding: 10px 15px;
}

.overflow {
	position: absolute;
	max-height: 20em;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	overflow-y: scroll;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	top: 50px;
	display: flex;
	flex-direction: column;
	gap: 0rem;
	padding: 0;
	margin: 0;

	background-color: white;
	z-index: 2;
	width: 100%;
}

.resultsList:hover {
	font-weight: 600;
	background-color: rgba(243, 240, 240, 0.692);
	cursor: pointer;
	text-decoration: underline;
}

.inputVal {
	height: 47px;
	position: relative;
	cursor: pointer;
	-webkit-margin-start: auto;
	border: 1px solid var(--primary-border);
	background-color: var(--primary-bg-light);
	border-radius: 2px;
	-webkit-margin-end: auto;
	margin-inline: auto;
	font-size: 14px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	margin-bottom: 0rem;
	padding: 12px 20px;
}
.inputVal::placeholder {
	color: var(--text-mute);
	font-size: 14px;
	opacity: 0.7;
}
.ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.box {
	position: relative;
	/* border: 1px solid var(--primary-border); */
	padding: 1rem;
	background-color: var(--primary-bg);
	border-radius: 3px;
}

@media (max-width: 600px) {
	.text1 {
		display: inline;
		line-height: 150%;
	}
	.justifyBetween {
		gap: 1rem;
	}

	.col {
		gap: 0.25rem;
	}

	.col1 {
		gap: 0.25rem;
	}

	.gapCol {
		gap: 0;
	}

	.changeDestinationButton {
		margin-left: 40px;
	}
}

@keyframes anim {
	0%,
	100% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 100%;
	}
}

@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}
