.modalTitle {
  text-align: center;
  width: 100%;
  font-size: 16px;
}

.topSection {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.qtyHandler {
  margin-bottom: 2rem;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkBox {
  width: fit-content;
  margin: auto;
  margin-bottom: 1.5em;
  font-size: 0.9em;
}

.title2 {
  text-align: center;
  font-size: 25px;
  margin: 1em;
  height: auto;
}

.textArea {
  margin-bottom: 1.5em;
}

.logo {
  position: absolute;
  left: 0;
  margin-left: 1em;
  margin-top: 2px;
}

.separator {
  margin-top: 1em;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.separatorText {
  color: var(--text-body);
  padding: 0 0.5rem;
  margin-bottom: 0rem !important;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #eeeeee;
}

.offerText {
  text-align: center;
  font-size: 16px;
  margin-bottom: 18px;
}

.closeIcon {
  z-index: 20;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}

.closeIcon:hover {
  cursor: pointer;
}

.offerSection {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
}

.amount {
  font-weight: 500;
  font-size: 18px;
}

.percentAmount {
  color: var(--text-body);
  font-size: 12px;
  font-weight: 300;
}

.percentAmountActive {
  color: white;
  font-size: 12px;
  font-weight: 300;
}

.activeBox {
  width: 30%;
  text-align: center;
  padding: 0.75rem;
  background-color: var(--primary-purple) !important;
  color: white !important;
  border-radius: 5px;
  transition: 0.2s ease;
}
.offerBox {
  width: 30%;
  text-align: center;
  padding: 0.75rem;
  border: 1px solid var(--primary-border);
  background-color: white;
  border-radius: 5px;
  transition: 0.2s ease;
}

.offerBox:hover {
  cursor: pointer;
}

.pColor {
  color: var(--text-main);
  padding-left: 0.4ch;
  font-weight: 500;
}

.modalText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.submitButton {
  background-color: var(--text-main);
  border-radius: 3px;
  border: 0 !important;
  font-family: "Editorial";
  width: 100%;
  align-self: center;
}

.yourOffer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 18px;
  font-weight: 400;
}

.textGreen {
  margin-left: 0.2ch;
  color: var(--primary-purple);
  font-weight: 500;
}

.padding {
  padding: 32px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.listingCard {
  padding-top: 32px;
  padding-bottom: 32px;

  position: relative;
  width: 100%;
  max-width: 40em;
}

.imgSizeModal {
  margin-left: auto;
  margin-right: auto;
  max-height: 8em;
  min-height: 8em;
  width: inherit;
  object-fit: contain;
}

.offerSent {
  text-align: center;
  padding-top: 1rem;
  font-weight: 600;
  font-size: 14px;
  color: #99a3a6;
}
.details {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  padding: 2px;
  display: block;
  height: auto;
  margin-top: 0.2px;
  text-align: left;
  margin-right: 1em;
}

.offerModalBody {
  text-align: center;
  font-weight: 400;
  color: #0e5c31;
}

.inputDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.offerInput {
  border: 1px solid #cdced2;
  border-radius: 3px;
  height: 44px;
  width: 100px;
  padding-left: 1.5rem;
  font-size: 18px;
}

.inputWrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.dollarSign {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  color: var(--primary-purple);
  font-weight: 400;
  font-size: 20px;
}

/* Buying & Selling Modal */

.main {
  padding-left: 20px;
  padding-right: 20px;
}

.infoBox {
  display: flex;
  margin-bottom: 10px;
}

.iconBack {
  z-index: 20;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
}

.offerInfo {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 12px;
}

.iconModalBuy {
  width: 7em;
  height: 7em;
  padding: 1em;
  border-radius: 20px;
  color: white;
  background-color: var(--primary-purple);
  margin-right: 20px;
}

.iconModal {
  width: 7em;
  height: 7em;
  padding: 1em;
  border-radius: 20px;
  color: white;
  background-color: var(--primary-purple);
  margin-right: 20px;
}

@media (max-width: 600px) {
  .infoBox {
    flex-wrap: wrap;
    text-align: center;
  }
  .infoSection {
    margin: auto;
  }

  .iconModalBuy {
    width: 5em;
    height: 5em;
    margin-right: 0;
    margin-bottom: 5px;
  }

  .iconModal {
    width: 5em;
    height: 5em;
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 600;
}

.infoText {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.map {
  height: 8em;
}

.noMargin {
  margin: 0 !important;
}

/* PBar */
.pBox {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.box {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg);
  width: 2em;
  height: 2em;
  margin-right: auto;
  margin-left: auto;
  border-radius: 1000px;
  transition: 1s;
}

.deliveryIcon {
  background-color: var(--primary-bg);
  color: var(--primary-purple);
  min-height: 3em;
  min-width: 3em;
  border-radius: 8px;
  padding: 8px;
}

.flexRow1 {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}

.flexRow2 {
  display: flex;
  flex-direction: row;
}

.address {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #99a3a6;
  font-size: 14px;
  margin-bottom: auto;
  margin-top: auto;
}

.numberActive {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-purple);
  color: white;
  width: 2em;
  height: 2em;
  margin-right: auto;
  margin-left: auto;
  border-radius: 1000px;
  transition: 1s;
}

.boxText {
  font-size: 12px;
  margin-top: 0.2rem;
  width: 90px;
}

.line {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
  position: relative;
}

.lineOne {
  border-radius: 50px;
  flex: 1;
  height: 3px;
  background-color: var(--primary-bg);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 1s;
}

.lineOneActive {
  border-radius: 50px;
  flex: 1;
  height: 3px;
  background-color: var(--primary-purple);
  transition: 1s;
}

/* Done */

.textSize {
  font-size: 14px;
}

.offCanvas {
  overflow: scroll;
}

.inputSection {
  background-color: white;
  width: 48%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--primary-bg);
  background-color: var(--primary-bg) 3a;
}
.inputSection1 {
  margin-top: 0.6em;

  background-color: white;
  width: fit-content;
  min-width: 100%;

  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon {
  position: absolute;
  top: 10px;
  left: 0;

  margin-left: 10px;
  margin-right: 10px;

  height: 25px;
  width: 25px;
}

.row {
  display: flex;
  flex-direction: row;
}

.pointer {
  cursor: pointer;
  position: relative;
  text-align: center;
  color: white;
  border-radius: 10px;
  width: inherit;
  margin-block: 5px;
  padding: 5px;
  background-color: #f7941d;
  border: none;
}

.absolute {
  color: var(--primary-purple);
  position: absolute;
  right: 0px;
  z-index: 2;
}

.pointer:hover {
  transform: scale(1.01);
  background-color: #ffbf00;
  font-weight: 600;
  min-width: 100% !important;
}

.errors {
  color: var(--primary-red-400);
  width: 100%;
  text-align: center;
  margin-top: 0.4em;
}

.leftSection {
  min-width: 10em;
  width: 100%;
  padding: 0.5em;
}

.rightSection {
  padding: 0.5em;
  padding-left: 1em;
  font-size: 14px;
}

.addressText {
  margin-top: 2em;
}

@media (max-width: 1000px) {
  .flexRow {
    flex-wrap: wrap-reverse;
  }

  .inputSection1 {
    width: 100%;
    margin-left: 0;
  }
}

/* Layout */

.container {
  display: flex;
  width: 70%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: auto;
  margin-top: 20px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.col {
  display: flex;
  justify-content: center;
}

/* text */

.instructions {
  font-size: 2.4vh;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  height: auto;
}

.listingItem {
  font-size: 2.4vh;
  width: 100%;
  border-bottom: solid rgba(235, 235, 235, 0.822) 1.5px;
  padding: 17px;
}

.title {
  font-size: 3vh;
}

.quantityInput {
  max-width: 100px;
}

/* listingImg */

.imgSize {
  width: 100%;
}

.carousel {
  width: 300px;
  display: inline-block;
}

/* Map */

/* imgLarge */

.carouselLarge {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  position: relative;
}

@media (min-width: 600px) {
  .listingImg:hover {
    transform: scale(1.5);
    transition: 0.2s ease;
  }
}

.listingImg {
  height: inherit;
  width: inherit;
  object-fit: cover;
  transition: 0.2s ease;
}

.map {
  width: 100%;
  min-width: 300px;
  margin-bottom: 1em;
}

/* Payment Stuff */

.paymentContainer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px;
  border-radius: 10px;
}
.navButtons {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  margin-bottom: 10rem;
}

.navButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;
  width: 95%;
}

.navButtonBack {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px !important;
  width: 95%;
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.stripeEl {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  margin-top: 1em;
}

.cardGroupSize {
  width: 100%;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.infoIcon {
  position: absolute;
  color: #99a3a6;
  right: 1;
  margin-left: 1px;
  cursor: pointer;
}

.colorText {
  color: var(--primary-purple);
  font-weight: 600;
}

.header {
  padding-top: 1em;
  padding-bottom: 1em;
}
.payTitle {
  font-weight: 600;
}

.onHover {
  cursor: pointer;
}

.onHover:hover {
  color: var(--primary-purple);
}

.stripeLogo {
  width: 10em;
}

.payPrice {
  color: var(--primary-purple);
  font-weight: 600;
  text-align: end;
}

.payInfo {
  font-size: 16px;
  font-style: italic;
  color: gray;
  margin-top: 1em;
  margin-bottom: 1em;
  border-bottom: solid rgba(235, 235, 235, 0.822) 1px;
  max-width: 80%;
}

.marginInline {
  -webkit-margin-start: 1em;
  -webkit-margin-end: 1em;
  margin-inline: 2em;
}

.paymentButton {
  margin-top: 1em;
  width: 100%;
}

@media (max-width: 700px) {
  .container {
    width: 100%;
  }
}

/* Layout */

.container {
  display: flex;
  width: 70%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: auto;
  margin-top: 20px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.col {
  display: flex;
  justify-content: center;
}

/* text */

.instructions {
  font-size: 2.4vh;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  height: auto;
}

.listingItem {
  font-size: 2.4vh;
  width: 100%;
  border-bottom: solid rgba(235, 235, 235, 0.822) 1.5px;
  padding: 17px;
}

.title {
  font-size: 3vh;
}

/* listingImg */

.imgSize {
  width: 100%;
}

.carousel {
  width: 300px;
  display: inline-block;
}

/* Map */

.map {
  width: 100%;
  min-width: 300px;
}

/* Payment Stuff */

.paymentContainer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px;
  border-radius: 10px;
}

.navButtons {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.cardGroupSize {
  width: 100%;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.infoIcon {
  position: absolute;
  color: #99a3a6;
  right: 1;
  margin-left: 1px;
  cursor: pointer;
}

.colorText {
  color: var(--primary-purple);
  font-weight: 600;
}

.header {
  padding-top: 1em;
  padding-bottom: 1em;
}
.payTitle {
  font-weight: 600;
}

.onHover {
  cursor: pointer;
}

.onHover:hover {
  color: var(--primary-purple);
}

.stripeLogo {
  width: 10em;
}

.payPrice {
  color: var(--primary-purple);
  font-weight: 600;
  text-align: end;
}

.payInfo {
  font-size: 16px;
  font-style: italic;
  color: gray;
  margin-bottom: 1em;
  border-bottom: solid rgba(235, 235, 235, 0.822) 1px;
  max-width: 80%;
}

.marginInline {
  -webkit-margin-start: 1em;
  -webkit-margin-end: 1em;
  margin-inline: 2em;
}

.error {
  color: var(--primary-red-400);
  text-align: center;
  padding: 1em;
}

.paymentButton {
  background-color: var(--primary-purple) !important;
  border: 0 !important;
  margin-top: 30px;
  height: 3rem;

  width: 100%;
}

.paymentButtonDisabled {
  background-color: var(--primary-purple) 6f !important;
  border: 0 !important;
  margin-top: 30px;
  height: 3rem;
  width: 100%;
}

.white {
  margin-left: 0.2rem;
  height: 1.2rem;
  width: 1.2rem;
  color: white !important;
}

.payment {
  background-color: #99a3a6 !important;
}

.protectedBox {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.protectTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.learnMore {
  text-decoration: underline;
  display: inline;
}
.learnMore:hover {
  cursor: pointer;
}

.protectHeader {
  font-size: 16px;
  font-weight: 600;
}
.protectBody {
  font-size: 14px;
  color: #6b6b6b;
  line-height: 20px;
  letter-spacing: -0.16px;
}

.shieldIcon {
  margin-top: auto;
  margin-bottom: auto;
  color: #68b368;
  height: 50px;
  width: 50px;
  margin-right: 0.75rem;
}

.cardLogo {
  max-height: 2em;
  max-width: 2em;
  display: inline;
}

.cardDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.cardPreview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  border: 0.5px solid rgba(189, 189, 189, 0.627);
  border-radius: 7px;
  margin-bottom: 0.5em;
  padding: 0.7em 0.4rem;
  color: gray;
  position: relative;
}

.cardPreviewActive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  border: 0.5px solid var(--primary-purple);
  border-radius: 7px;
  margin-bottom: 0.5em;
  padding: 0.7em 0.4rem;
  color: gray;
  position: relative;
}

.circle {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  width: 20px;
  height: 20px;

  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  border: 0.5px solid #99a3a6;
}

.circleActive {
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  border: 0.5px solid var(--primary-purple);
  background-color: var(--primary-purple);
}

.cardPreview:hover {
  cursor: pointer;
  color: white;
  background-color: rgba(168, 168, 168, 0.398);
  border: 0.5px solid white;
}

.cardPreviewAdd:hover {
  cursor: pointer;
}

.cardPreviewAdd {
  display: flex;
  align-items: center;
  padding: 1em;
  color: var(--primary-purple);
}

.cardNumber {
  text-align: left;
  width: 50%;
  margin-left: 2em;
  /* margin-right: 3em; */
  color: #6b6b6b;
}

.addIcon {
  color: var(--primary-purple);
  height: 24px;
  width: 24px;
  display: inline;
  margin-right: 0.75rem;
}

.addText {
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  height: 24px;
}

.removeIcon {
  color: #99a3a6;
  height: 25px;
  width: 25px;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  right: 1em;
}

.removeIcon:hover {
  cursor: no-drop;
}

.paymentMethodSection {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.orPayWith {
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding: 1em;
}

.paypalPayment {
  width: 80%;

  margin: auto;
}

@media (max-width: 700px) {
  .container {
    width: 100%;
  }
}

.buttonDelivery {
  width: 40%;
  height: 3em;

  color: var(--primary-purple) !important;
  background-color: white !important;
}

.buttonPickup {
  height: 3em;

  width: 49%;
}

.Link {
  text-decoration: none;
  color: black;
}

.promo {
  height: 44px !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  border: 1px solid rgba(50, 50, 93, 0.1) !important;
}

.icon {
  color: white;
  width: 20px;
  height: 20px;
}

.offerModal {
  background-color: #99a3a6;
}

.submitButton:hover {
  cursor: pointer;
  transform: scale(1.01);
  opacity: 0.8;
}

.promoBox {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
}

.flex {
  display: flex;
  flex-direction: row;
}

.deliveryIcon {
  background-color: var(--primary-bg);
  color: var(--primary-purple);
  min-height: 3em;
  min-width: 3em;
  border-radius: 8px;
  padding: 8px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexRow1 {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}

.sub {
  color: #6b6b6b;
  font-size: 14px;
}

.paySec {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.2rem;
}
.main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.finalAmounts {
  color: #99a3a6;
  font-size: 14px;
  margin-left: auto;
  color: black;
}

.totalAmount {
  color: #99a3a6;
  font-size: 14px;
  margin-left: auto;
  font-weight: 600;
  color: black;
}

.discount {
  color: var(--primary-red-400);
  font-size: 14px;
  margin-left: auto;
}

.total {
  color: #222222;
  font-size: 14px;
  font-weight: 600;
}

.address {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #6b6b6b;
  font-size: 14px;
  margin-bottom: auto;
  margin-top: auto;
}

.listingBox {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: inherit;
  background-color: white;
  padding: 1.5em;
  border-radius: 8px;
  overflow-y: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 2rem;
}

.listingBox1 {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: inherit;
  background-color: white;
  padding: 1em;
  border-radius: 8px;
  height: fit-content;
  width: 80%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.listingBoxInfo {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: white;
  padding: 1em;
  border-radius: 8px;
  height: fit-content;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.leftSection1 {
  width: 45%;
}

.leftSection {
  width: 35%;
}
.rightSection {
  width: 60%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 16px;
  font-weight: 800;
}

.qty {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
}
.add {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  border: 0.5px solid black;
  padding: 7px;
}

.add:hover {
  cursor: pointer;
}
.totalQty {
  color: var(--primary-purple);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.listingPrice {
  background-color: rgba(99, 183, 99, 0.15);
  font-size: 16px;
  color: var(--primary-purple);
  font-weight: 600;
  border-radius: 8px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  position: relative;
}

.estRetail {
  color: var(--text-mute);
  text-decoration: line-through;
  padding: 5px;
}

.prices {
  margin-top: 0.5rem;

  display: flex;
  flex-direction: row;
}

.buttonDelivery {
  width: 49%;
  height: 3em;

  color: var(--primary-purple) !important;
  background-color: white !important;
}

.buttonPickup {
  height: 3em;

  width: 49%;
}

.button {
  width: 100%;
}

.listImg {
  object-fit: contain;
  height: inherit;
  width: inherit;
}

.carouselImg {
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}

/* Done */

.retailLink {
  color: var(--primary-purple);
}

.space {
  margin: 0.5em;
}

.marginAuto {
  margin-top: auto;
}

.minHeight {
  max-width: 100%;
}

/* cardgroup size */
.cardGroupSize {
  margin-top: 1em;
  max-width: 40em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 1em;
  border-radius: 10px;
  background-color: white;
}

.onHover {
  cursor: pointer;
}

.onHover:hover {
  color: var(--primary-purple);
}

.infoIcon {
  position: absolute;
  color: #99a3a6;
  right: 1;
  margin-left: 1px;
  cursor: pointer;
}
.header {
  padding-top: 1em;
  padding-bottom: 1em;
}

.marginInline {
  -webkit-margin-start: 1em;
  -webkit-margin-end: 1em;
  margin-inline: 2em;
}

.carousel {
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
}

/* InfoSection */
.cardSize {
  min-height: 300px;
  padding: 1em;
}

.cardBorder {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 2em;
}

.noMargin {
  margin-top: 0 !important;
}

@media (max-width: 1040px) {
  .cardSize {
    min-height: 300px;
  }
}

@media (max-width: 800px) {
  .cardSize {
    min-height: 200px;
  }

  .listingBox1 {
    width: 100%;
  }

  .cardBorder {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    padding: 0.2em;
  }
}

@media (max-width: 420px) {
  .cardSize {
    min-height: 200px;
  }
  .marginTop {
    margin-top: 0em;
  }
}

/* image sizing */

.price {
  margin-bottom: 1px;
  font-size: 1.5rem;
  color: var(--text-main);
  font-weight: 600;
}

/* button div */
.buttonDiv {
  margin-left: 32px;
  margin-right: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.labelClass {
  font-weight: 600;
}

.section1 {
  padding: 30px 15px;
  margin-top: 30px;

  border-bottom: 15px solid var(--primary-bg);
  border-top: 15px solid var(--primary-bg);
}

.section {
  padding: 30px 15px;
  border-bottom: 15px solid var(--primary-bg);
}

.sectionError {
  padding: 15px 10px;
  background-color: var(--primary-bg);
  border-bottom: 15px solid var(--primary-bg);
}

.cartButtonSize {
  width: 100%;
}

.titleSpecs {
  font-weight: 600;
}

.listItemSpecs {
  margin-bottom: 1;
  margin-top: 1;
}

.subTotal {
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  padding: 10px;
  border-radius: 10px;
}

.conditionBadgeSize {
  display: grid;
  max-width: 200px;
  margin-top: 1px;
  color: white;
  background-color: #99a3a6;
  width: auto;
  height: auto;
  text-align: center;
  display: inline;
  padding: 4px;
  border-radius: 7px;
}

.distanceBadgeSize {
  display: grid;
  width: 20%;
  margin-left: 30px;
  margin-top: 1px;
}
