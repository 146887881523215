.separator {
  margin-top: 1em;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.separatorText {
  color: #99a3a6;
  padding: 0 0.5rem;
  margin-bottom: 0rem !important;
}

.line {
  flex: 1;
  height: 1px;
  background-color: rgb(232 234 239);
}

.margin {
  margin-bottom: 14px;
}

.paymentsContainer {
  margin: auto;
  width: 100%;
}

@media (max-width: 600px) {
  .paymentsContainer {
    width: 100%;
  }
}
