.biShareDiv {
	margin-left: 2rem;
}

.biShare {
	width: 20px;
	height: 20px;
	margin-right: 0.25rem;
	color: var(--text-mute);
}

.share {
	cursor: pointer;

	display: flex;
	align-items: center;
	/* text-align: end; */
	border: 1px solid var(--primary-border);
	padding: 5px 12px;
	color: var(--text-body);
	border-radius: 3px;

	background-color: white;
	transition: all 0.2s ease-in-out;
}

.share:hover {
	opacity: 0.8;
}

.shareText {
	margin-top: 1px;
	color: var(--text-body);
	font-weight: 500;
	font-size: 14px;
}
