.modal {
}

.sellerTags {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 0rem;
  flex-wrap: wrap;
}

.tag {
  white-space: nowrap;
  background-color: var(--primary-bg);
  border-radius: 999999px;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  line-height: 1em;
  list-style: none;
  color: var(--text-main);
  text-transform: capitalize;
}

.main {
  position: relative;
  padding: 2rem;
  padding-top: 3.5rem;
}

.closeIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: rgb(38, 38, 38);
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.colHeader {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0rem;
}

.headerText {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0rem;
}

.reviews {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
}

.ratingView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avg {
  margin-top: 2px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.buttonPaginate {
  background-color: var(--text-main);
  color: white;
  padding: 10px 20px;
}

.reviewsContainer {
  overflow-y: auto; /* Change from 'scroll' to 'auto' */
  max-height: 30rem;
  display: flex;
  flex-direction: column;
}

.mute {
  font-size: 12px;
  color: var(--text-mute);
  margin-bottom: 0rem;
  margin-top: 1.5px;
}

.reviewContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-left: 0rem;
  row-gap: 0.3rem;

  border-bottom: 1px solid var(--primary-border);
}

.firstName {
  font-size: 13px;
  font-family: "Editorial";
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0rem;
}

.ratingsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
}

.badge {
  background-color: white;
  color: var(--text-mute);
  border: 1px solid var(--primary-border);
  padding: 0px 10px;
  border-radius: 9999px;
  font-size: 12px;
}

.otherBadge {
  background-color: white;
  color: var(--text-mute);
  border: 1px solid var(--primary-border);
  padding: 0px 10px;
  border-radius: 9999px;
  font-size: 12px;
}

.listingContainer {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.listingImg {
  width: 50px;
  height: 50px;
  border: 1px solid var(--primary-border);
}

.listingTitle {
  font-size: 14px;
  color: var(--text-body);
  font-weight: 400;
  font-family: "Editorial";
  line-height: 150%;
  margin-bottom: 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.reviewText {
  font-size: 14px;
  color: var(--text-body);
  margin-bottom: 0rem;
}

.listingInfo {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .reviewsContainer {
    max-height: 20rem;
  }
}
