.listingCard {
  border-radius: 2px !important;
  width: 100%;
  /* padding: 0.5rem; */
  background-color: white;
  margin-right: auto;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  margin-top: 0em;
}

.listing {
  background-color: var(--primary-bg);
  margin-right: 16px;
  flex: 0 0 calc(20% - 16px);
  position: relative;
  border-radius: 3px;
  border: 0.5px solid var(--primary-border);
}

.gap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
}

.listingCardCategory {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}

.listingImg1Category {
  border-radius: 8px;
  object-fit: cover;

  height: 10em;
  width: 100%;
}

.titleText {
  width: 100%;
  font-size: 20px;
  padding-top: 1rem;
  font-weight: 800;
  color: var(--primary-purple);
  text-align: center;
}

.viewedXTimes {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  height: 2em;
  top: 0em;
  right: 0.2em;
  margin: auto;
  color: #222222;
  font-weight: 300;
  text-shadow: 0.5px 0.5px 1px white;
}

.viewedIcon {
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  margin-right: 0.2rem;
  width: 20px;
}

.dealBadge {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  height: 2em;
  top: 1em;
  left: 1em;
  margin: auto;
  /* background-color: var(--primary-purple) !important; */
  border-radius: 2px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: var(--text-main);

  font-size: 13px;
  font-weight: 400;
}

.text {
  margin-top: 7px;
  font-size: 16px;
  margin-bottom: auto;
  margin-right: 0.1em;
}

.Link {
  text-decoration: none;
  color: black;
}

.Link:hover {
  text-decoration: none;
}

.postDate {
  margin: 0%;
  padding: 0%;
  color: gray;
  font-size: 13px;
  font-style: italic;
}

.retailPrice {
  color: var(--text-mute);
  text-decoration: line-through;
  margin-left: 5px;
  font-size: 14px;
  letter-spacing: 0.02em;
}

.price {
  color: var(--text-main);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.carousel {
  overflow: hidden;
  position: relative;
  width: inherit;
  text-align: center;
  display: flex;
  object-fit: cover;
  justify-content: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.carouselPlaceholder {
  position: relative;
  width: inherit;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 207px;
  width: 100%;
  box-shadow: var(--primary-shadow-1);
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(210, 27%, 96%) 0%,
    hsla(180, 3%, 78%, 0.433) 50%,
    hsl(210, 28%, 93%) 100%
  );
  animation: anim 2s infinite;
  border-radius: 3px;
}

.cardBodyPlaceholder {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  /* padding-top: 0.8rem;
	padding-left: 0.2rem; */
  padding-top: 0.5rem;
  background-color: white;
}

.placeholderBadge {
  background-color: var(--primary-bg);
  width: 100%;
  height: 10px;
  border-radius: 8px;
  margin-right: auto;
  margin-left: auto;
}

.white {
  display: none;
}

.listingImg1 {
  height: 13.5em;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  z-index: 1;
  transition: 0.2s;
}

.listingImg1:hover {
  transform: scale(1.05);
}

.cardBody {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 7em;
  max-height: 7em;
  /* padding-top: 0.8rem;
	padding-left: 0.2rem; */
  padding-top: 1rem;
  padding-left: 1rem;
}

.carouselContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.next {
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(76, 76, 76, 0.28);

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-60%);
}

.previous {
  cursor: pointer;

  box-shadow: 0px 2px 4px rgba(76, 76, 76, 0.28);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1;
  position: absolute;
  left: 10px;
  top: 40%;
  transform: translateY(-60%);
}

.arrow {
  width: 40%;
}

.carouselMask {
  cursor: grab;
  position: relative;
  display: flex;
  justify-content: flex-start;
}

.carouselMask:active {
  cursor: grabbing;
}

/* Styles for each item within the carousel */
.carouselListing {
  margin-right: 16px;
  flex: 0 0 calc(20% - 16px);
}

@media (max-width: 600px) {
  .gap {
    display: inline-grid; /* Makes the grid itself inline-level so it can grow wider than its container */
    gap: 1rem;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, minmax(50%, max-content));
  }

  .carouselListing {
    margin-right: 8px;
    flex: 0 0 calc(50% - 8px);
  }

  .listing {
    margin-right: 8px;
    flex: 0 0 calc(50% - 8px);
  }

  .listingImg1 {
    max-height: 9em;
  }

  .carouselPlaceholder {
    max-height: 9em;
    width: 100% !important;
  }

  .cardBody {
    min-height: 8em;
    max-height: 8em;
  }

  .titleText {
    padding-top: 0.5rem;
    font-size: 16px;
  }
}

/* Card Body */

.titleDiv {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 1rem;
}
.title {
  color: black;
  font-size: 12px;
  max-width: 90%;
  letter-spacing: 0.02em;
  margin-bottom: 2px !important;
}

.infoSection {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

@keyframes anim {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@keyframes wobble {
  25% {
    transform: rotate(2deg);
    transform: scale(1.5);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.itemClass {
  width: auto !important;
  margin: 1rem;
}
