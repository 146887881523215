.bg {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: var(--primary-bg);
}

.main {
	display: flex;
	flex-direction: row;
	height: calc(100vh - 75px);
	max-width: var(--max-width);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.leftSection {
	display: flex;
	height: 100%;
	justify-content: center;
	padding-top: 2rem;
	width: 20%;
	overflow-y: scroll;
	padding-bottom: 2rem;
}

.navButtons {
	background-color: var(--text-main);
	padding: 1.5rem;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	width: 100%;
	height: fit-content;

	box-shadow: var(--primary-box-shadow-1);
}

.rightSection {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 50px;
	overflow-y: scroll;
	background-color: var(--primary-bg);
}

/* user Info */

.userInfo {
	display: flex;
	flex-direction: row;
	column-gap: 15px;
	text-transform: capitalize;
	/* align-items: center; */
}

.loadingUser {
	display: flex;
	flex-direction: row;
	column-gap: 15px;
	height: 40px;

	width: 100%;
}

.imgLoad {
	height: 35px;
	width: 35px;
	border-radius: 100px;
	background-color: white;
}

.loadingBar {
	height: 10px;
	width: 100px;
	border-radius: 5px;
	background-color: white;
}

.loadingBar1 {
	height: 10px;
	width: 50px;
	border-radius: 5px;
	background-color: white;
}

.userCol {
	display: flex;
	flex-direction: column;
	row-gap: 2px;
	width: fit-content;
	height: 100%;
	justify-content: center;
}

.userName {
	margin-bottom: 0rem;
	font-family: 'Editorial';
	line-height: 150%;
	font-size: 12px;
	font-weight: 400;
	color: white;
}

.link {
	color: var(--text-mute);
	font-size: 10px;
}

.userImg {
	height: 35px;
	width: 35px;
	object-fit: cover;
	border-radius: 100%;
}

.container {
	max-width: 950px;
	width: 100%;
	height: fit-content;
}

.tabs {
	display: flex;

	flex-direction: column;
	row-gap: 1rem;
}

.tabNotAvailable {
	display: flex;
	flex-direction: row;
	column-gap: 7px;
	align-items: center;
	padding: 5px 7px;
	opacity: 0.3;
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.tab {
	display: flex;
	flex-direction: row;
	column-gap: 7px;
	align-items: center;
	padding: 5px 7px;
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.tab:hover {
	opacity: 0.5;
}

.disabled {
	display: flex;
	flex-direction: row;
	column-gap: 7px;
	align-items: center;
	padding: 5px 7px;
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	opacity: 0.2;
}

.activeTab {
	background-color: white;
	display: flex;
	flex-direction: row;
	column-gap: 7px;
	align-items: center;
	padding: 5px 7px;
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	color: var(--text-main) !important;
}

.activeTab .tabName {
	color: var(--text-main) !important;
}

.activeTab .icon {
	color: var(--text-main) !important;
}

/* Subtabs */

.subTab {
	margin-left: 1rem;
	margin-top: 0.2rem;
	display: flex;
	flex-direction: row;
	column-gap: 7px;
	align-items: center;
	padding: 5px 7px;
	border-radius: 3px;
	color: var(--text-body);
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.activeSubTab {
	margin-left: 1rem;

	background-color: white;
	margin-top: 0.2rem;

	display: flex;
	flex-direction: row;
	column-gap: 7px;
	align-items: center;
	padding: 5px 7px;
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	color: var(--text-main) !important;
}

.activeSubTab .tabName {
	color: var(--text-main) !important;
}

.activeSubTab .icon {
	color: var(--text-main) !important;
}

.subTabsContainer {
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}

.subTabContainerMarginTop {
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	margin-top: auto;
}

.icon {
	border-radius: 0px;
	margin-bottom: 1px;
	color: rgb(193, 193, 193);
}

.tabName {
	margin-top: 2px;
	margin-bottom: 0rem;
	font-weight: 400;
	font-size: 12px;
	color: rgb(193, 193, 193);
}

.mobileNav {
	display: none;
}

.iconBack {
	width: 1.5rem;
	height: 1.5rem;
}

.mobileHidden {
	width: 0;
	max-width: 0;
	padding: 0;
	display: none;
	border-right: none;
	transition: width 0.5s ease; /* Add CSS transition for smooth hiding */
}

.typeHeader {
	margin-bottom: 0rem;
	font-size: 10px;
	font-weight: 400;
	color: rgb(193, 193, 193);
}

.group {
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}
.groupSmall {
	display: flex;
	flex-direction: column;
	row-gap: 0.1rem;
}

@media (max-width: 600px) {
	.main {
		height: calc(100vh - 51.5px);
	}
	.leftSection {
		display: none;
	}

	.mobileNav {
		position: absolute;
		left: -1.75rem;
		width: 3.5rem;
		height: 3.5rem;
		padding-right: 5px;
		display: flex;
		align-items: center;
		justify-content: right;
		top: 1.5rem;
		background-color: var(--text-main);
		color: white;
		border-radius: 50%;
	}

	.rightSection {
		padding: 1.5rem;
		padding-top: 2.5rem;
		padding-bottom: 5rem;
	}
}
