.listing {
  background-color: var(--primary-bg);
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1/1;
}

.topSection {
  height: 70%;
}

.bottomSection {
  height: 30%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1rem;
}

.row1,
.row {
  height: 33%;
  border-radius: 3px;
  width: 50%; /* Only for .row1, remove this for .row if it should be 100% */
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    /* Lightest shade, almost white but still has contrast */ hsl(0, 0%, 80%)
      50%,
    /* Medium shade for visible contrast */ hsl(0, 0%, 85%) 100%
      /* Light shade that's between the lightest and medium */
  );
  animation: anim 2s linear infinite;
}

.row {
  width: 100%;
}

@keyframes anim {
  0%,
  100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
}
