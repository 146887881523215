.offcanvas {
  max-width: 80%;
  /* max-width: 200px; */
  background-color: var(--text-main);
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.tabs {
  display: flex;

  flex-direction: column;
  row-gap: 1rem;
}

.userInfo {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 10px;
  text-transform: capitalize;
  /* align-items: center; */
}

.userImg {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.userCol {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  width: fit-content;
  height: 100%;
  justify-content: center;
}

.userName {
  margin-bottom: 0rem;
  font-family: "Editorial";
  line-height: 150%;
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.link {
  color: var(--text-mute);
  font-size: 11px;
}

.group {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.groupSmall {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
}

.tab {
  display: flex;
  flex-direction: row;
  column-gap: 7px;
  align-items: center;
  padding: 5px 7px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.tab:hover {
  opacity: 0.5;
}

.activeTab {
  background-color: white;
  display: flex;
  flex-direction: row;
  column-gap: 7px;
  align-items: center;
  padding: 5px 7px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: var(--text-main) !important;
}

.activeTab .tabName {
  color: var(--text-main) !important;
}

.activeTab .icon {
  color: var(--text-main) !important;
}

.subTab {
  margin-left: 1rem;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  column-gap: 7px;
  align-items: center;
  padding: 5px 7px;
  border-radius: 3px;
  color: var(--text-body);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.activeSubTab {
  margin-left: 1rem;

  background-color: white;
  margin-top: 0.2rem;

  display: flex;
  flex-direction: row;
  column-gap: 7px;
  align-items: center;
  padding: 5px 7px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: var(--text-main) !important;
}

.activeSubTab .tabName {
  color: var(--text-main) !important;
}

.activeSubTab .icon {
  color: var(--text-main) !important;
}

.icon {
  border-radius: 0px;
  margin-bottom: 1px;
  color: rgb(193, 193, 193);
}

.tabName {
  margin-top: 2px;
  margin-bottom: 0rem;
  font-size: 12px;
  color: rgb(193, 193, 193);
}

.typeHeader {
  margin-bottom: 0rem;
  font-size: 10px;
  font-weight: 400;
  color: rgb(193, 193, 193);
}
